import React from "react";

import { AppUser } from "./user";
import { Route, Router, RouterSwitch } from "./routing";
import routes from "./routing/routes";
import Root from "./views/Root";

import UserMainNav from "./containers/UserMainNav";
import UserMetaNav from "./containers/UserMetaNav";
import DocumentationsView from "./views/Documentations";
import DocumentationCreateView from "./views/DocumentationCreate";
import ScribblesView from "./views/Scribbles";
import ScribbleCreateView from "./views/ScribbleCreate";

function App() {
  return (
    <Router routes={routes}>
      <AppUser>
        <UserMainNav />

        <RouterSwitch>
          <Route route={routes.root}>
            <Root />
          </Route>
          <Route route={routes.documentations}>
            <DocumentationsView />
          </Route>
          <Route route={routes.documentationCreate}>
            <DocumentationCreateView />
          </Route>
          <Route route={routes.scribbles}>
            <ScribblesView />
          </Route>
          <Route route={routes.scribbleCreate}>
            <ScribbleCreateView />
          </Route>
        </RouterSwitch>

        <UserMetaNav />
      </AppUser>
    </Router>
  );
}

export default App;
