import React, { useContext, useEffect, useState } from "react";
import find from "lodash/find";
import matchPath from "./utils/matchPath";
import generatePath from "./utils/generatePath";
import ClickContainer from "../components/basics/ClickContainer";
import { useUser } from "../user";

const RouterContext = React.createContext();

const generateRoute = (inputRoute, params) => {
  try {
    const generatedPath = generatePath(inputRoute.path, params);
    return { path: inputRoute.path, generatedPath };
  } catch (error) {
    // eslint-disable-next-line no-console
    console.warn("Cant create generated path. Maybe missing params?");
    return { path: inputRoute.path, generatedPath: inputRoute.path };
  }
};

const getRouteByWindowPathname = (routes) => {
  const windowPathname = window.location.pathname;
  const routesMap = Object.keys(routes).map((k) => ({
    key: k,
    path: routes[k].path,
    relatedItem: routes[k],
    isMatch: matchPath(windowPathname, routes[k].path)
  }));

  const found = find(routesMap, (d) => d.isMatch?.isExact);
  return found?.relatedItem;
};

const getRouteSettingByPath = (path, routes) => {
  const found = find(
    Object.keys(routes).map((k) => ({
      key: k,
      path: routes[k].path,
      relatedItem: routes[k]
    })),
    { path }
  );
  return found?.relatedItem?.settings || {};
};

export const Router = ({
  routes = { root: { path: "/", settings: {} } },
  children
}) => {
  const [route, setRoute] = useState(() => {
    if (window.history.state?.currentRoute) {
      // saved in browser state
      return window.history.state?.currentRoute;
    }
    console.log("window.location.", window.location);
    const routeFromWindow = getRouteByWindowPathname(routes);
    if (routeFromWindow) {
      console.log({ routeFromWindow });
      return generateRoute(routeFromWindow, {});
    }
    // default
    return generateRoute(routes.root, {});
  });

  const [settings, setSettings] = useState(() =>
    getRouteSettingByPath(route.path, routes)
  );

  useEffect(() => {
    const nativeHistoryNavigation = (event) => {
      // set route from history state (user is using forwarde, backward button)
      if (event.state?.currentRoute) {
        setRoute(event.state.currentRoute);
        setSettings(
          getRouteSettingByPath(event.state.currentRoute.path, routes)
        );
      }
    };
    window.addEventListener("popstate", nativeHistoryNavigation);
    return () => {
      window.removeEventListener("popstate", nativeHistoryNavigation);
    };
  }, []);

  const setNextRoute = (
    inputRoute,
    params,
    historyAction = "push",
    resetScroll = true
  ) => {
    const nextRoute = generateRoute(inputRoute, params);
    if (historyAction === "push") {
      window.history.replaceState(
        {
          currentRoute: {
            path: route.path,
            generatedPath: route.generatedPath
          }
        },
        "",
        route.generatedPath
      );
      window.history.pushState(
        {
          currentRoute: {
            path: nextRoute.path,
            generatedPath: nextRoute.generatedPath
          }
        },
        "",
        nextRoute.generatedPath
      );
    }
    setRoute({ path: nextRoute.path, generatedPath: nextRoute.generatedPath });
    setSettings(inputRoute.settings || {});
    if (resetScroll) {
      window.scrollTo(0, 0);
    }
  };

  return (
    <RouterContext.Provider value={{ route, setNextRoute, settings }}>
      {children}
    </RouterContext.Provider>
  );
};

export const Route = ({ route, children }) => <div>{children}</div>;

export const useRouter = () => {
  const router = useContext(RouterContext);
  return router;
};

export const RouterLink = ({
  label,
  route,
  params,
  children,
  Component,
  historyAction = "push",
  resetScroll = true
}) => {
  const router = useRouter();

  return React.createElement(
    Component || ClickContainer,
    {
      isMatch: matchPath(router.route.generatedPath, route.path),
      onClick: () => {
        router.setNextRoute(route, params, historyAction, resetScroll);
      }
    },
    children || label
  );
};

const RouteView = ({ children, isMatch, userHasAccess = true }) => {
  const visible = isMatch && isMatch?.isExact;

  if (!visible) {
    return null;
  }

  if (!userHasAccess) {
    return (
      <div
        style={{
          display: "flex",
          flex: "1 0 auto",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        <div>Sorry, you don&rsquo;t have access to this.</div>
        <div>
          <a href="/">Start from Homepage again.</a>
        </div>
      </div>
    );
  }

  if (typeof children === "function") {
    return children({ ...isMatch.params });
  }
  return children;
};

export const RouterSwitch = ({ children, Component }) => {
  const router = useRouter();
  const { user } = useUser();

  return React.Children.map(children, (child) => {
    if (!child.props.route) {
      return child;
    }

    let userHasAccess = true;
    if (child.props.route.accessCheck) {
      userHasAccess = child.props.route.accessCheck(user);
    }

    return React.createElement(
      Component || RouteView,
      {
        isMatch: matchPath(router.route.generatedPath, child.props.route.path),
        userHasAccess
      },
      child.props.children
    );
  });
};
