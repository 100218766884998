import React, { useState } from "react";
import { Form, Button, Input, Typography, Alert } from "antd";
import { LockFilled } from "@ant-design/icons";
import { useStudoApi } from "../connecting/studo";
import { useUser } from "../user";

const ExtendedError = ({ error }) => {
  const [showMore, setShowMore] = useState(false);
  return (
    <div style={{ maxWidth: 400 }}>
      <Button type="link" onClick={() => setShowMore(true)}>
        Mehr zum Fehler anzeigen
      </Button>
      {showMore && (
        <>
          <pre style={{ color: "#fff" }}>{JSON.stringify(error, null, 2)}</pre>
          <pre style={{ color: "#fff" }}>
            {JSON.stringify(
              { data: error?.response?.data, status: error?.response?.status },
              null,
              2
            )}
          </pre>
        </>
      )}
    </div>
  );
};

export default () => {
  const [form] = Form.useForm();
  const { setUser } = useUser();

  const authApi = useStudoApi({
    onSuccess: (data) => {
      if (data.user) {
        setUser((s) => ({
          ...s,
          uuid: data.user.uuid,
          name: data.user.name,
          roleName: data.user.roleName,
          studoToken: data.token
        }));
      }
    }
  });

  const handleFormSubmit = (formValues) => {
    if (formValues.key && !authApi.pending) {
      authApi.runAction({
        method: "post",
        path: "/auth/signin",
        data: { key: formValues.key }
      });
    }
  };

  return (
    <Form
      form={form}
      onFinish={handleFormSubmit}
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center"
      }}
    >
      <Typography.Title style={{ textAlign: "center" }}>Login</Typography.Title>
      {authApi.error && (
        <>
          <Alert
            type="error"
            showIcon
            message="Oh nein."
            description={
              authApi.error?.response?.status === 400
                ? "Der Schlüssel scheint nicht zu stimmen."
                : "Beim login ist ein Fehler aufgetreten."
            }
          />

          <ExtendedError error={authApi.error} />
        </>
      )}
      <Form.Item
        name="key"
        rules={[{ required: true, message: "Gib deinen Schlüssel ein." }]}
      >
        <Input.Password
          type="text"
          placeholder="Dein Schlüssel"
          disabled={authApi.pending}
        />
      </Form.Item>
      <Button
        type="primary"
        htmlType="submit"
        icon={<LockFilled />}
        loading={authApi.pending}
      >
        Login
      </Button>
    </Form>
  );
};
