import { useState } from "react";
import axios from "axios";

const getBaseUrl = () => {
  if (process.env.NODE_ENV === "development") {
    switch (window.location.host) {
      case "localhost:3000":
        return "http://localhost:3020";
      case "192.168.178.25:3000":
        return "http://192.168.178.25:3020";
      // case "demo-pwa.smartplatz.app":
      //   return "https://demo-api.smartplatz.app";
      default:
        return "https://studo-api.apiwire.localhost";
    }
  } else {
    return process.env.REACT_APP_STUDOAPI;
  }
};

export const axiosStudo = axios.create({
  baseURL: getBaseUrl(),
  timeout: 20000,
  withCredentials: true,
  headers: { "X-Studo": "Hello from Studo-Client" }
});

export const completeImageUrl = (imageUrl) => `${getBaseUrl()}${imageUrl}`;

export const useStudoApi = ({
  onSuccess = (data) => {},
  onError = (error) => {},
  onPending = (bool) => {},
  onUploadProgress = (val) => {},
  onDownloadProgress = (val) => {},
  onStart = () => {},
  onEnd = () => {},
  token = null
} = {}) => {
  const [apiData, setApiData] = useState(null);
  const [apiError, setApiError] = useState(null);
  const [apiPending, setApiPending] = useState(false);

  const runAction = async ({
    method,
    path,
    data = {},
    paramsData = {},
    upload = false,
    isBlob = false
  }) => {
    try {
      setApiPending(true);
      setApiError(null);
      onPending(true);
      onStart();

      let sendData = data;
      if (upload) {
        if (data instanceof FormData) {
          sendData = data;
        } else {
          const formData = new FormData();
          const files = []; // ATTENTION: Files always have to be on the end, after other fields to prevent multer problems
          Object.keys(data).forEach((dataKey) => {
            const dataValue = data[dataKey];
            if (dataValue instanceof File) {
              // take out the files
              files.push({ fileKey: dataKey, file: dataValue });
            } else {
              formData.append(dataKey, dataValue);
            }
          });
          files.forEach(({ fileKey, file }) => {
            console.log("add file to formdata", fileKey, file);
            formData.append(fileKey, file, file.name);
          });
          sendData = formData;
        }
      }

      const request = await axiosStudo({
        method,
        url: path,
        data: sendData,
        params: paramsData,
        ...(isBlob && { responseType: "blob" }),
        headers: {
          ...(upload && { "Content-Type": "multipart/form-data" }),
          ...(token && { "x-studo-auth": `Moehre ${token}` })
        },
        onUploadProgress: ({ total, loaded }) =>
          onUploadProgress(total / loaded),
        onDownloadProgress: ({ total, loaded }) =>
          onDownloadProgress(total / loaded)
      });
      setApiPending(false);
      setApiData(request.data);
      onPending(false);
      onSuccess(request.data);
      onEnd();
    } catch (error) {
      setApiError(error);
      setApiPending(false);
      onPending(false);
      onError(error);
      onEnd();
    }
  };
  return { runAction, data: apiData, pending: apiPending, error: apiError };
};
