import React from "react";
import { Typography } from "antd";

import { useUser } from "../user";
import routes from "../routing/routes";
import { RouterLink, useRouter } from "../routing";
import ClickContainer from "../components/basics/ClickContainer";

const MainNavLink = ({ children, isMatch, onClick }) => {
  console.log(isMatch?.isExact);
  return (
    <div className={`main-nav-link ${isMatch?.isExact ? "link-active" : ""}`}>
      <ClickContainer onClick={onClick}>
        <Typography.Title style={{ textAlign: "center", marginBottom: 0 }}>
          {children}
        </Typography.Title>
      </ClickContainer>
    </div>
  );
};

export default () => {
  const { user } = useUser();
  const { settings } = useRouter();

  if (!user?.uuid) {
    return null;
  }

  if (settings?.hideUserMainNav) {
    return null;
  }

  return (
    <div
      className="nav"
      style={{
        overflowX: "auto",
        textAlign: "center",
        whiteSpace: "nowrap",
        margin: "3rem 0"
      }}
    >
      <div style={{ display: "inline-block", padding: "1rem" }}>
        <RouterLink
          label="Eintagsfliegen"
          route={routes.scribbles}
          historyAction="push"
          Component={MainNavLink}
          resetScroll={false}
        >
          Eintagsfliegen
        </RouterLink>
      </div>
      <div style={{ display: "inline-block", padding: "1rem" }}>
        <RouterLink
          label="Dokumentationen"
          route={routes.documentations}
          historyAction="push"
          Component={MainNavLink}
          resetScroll={false}
        >
          Dokumentationen
        </RouterLink>
      </div>
    </div>
  );
};
