import { ArrowRightOutlined } from "@ant-design/icons";
import { Typography } from "antd";
import React from "react";
import LoginForm from "../containers/LoginForm";
import { RouterLink } from "../routing";
import routes from "../routing/routes";
import { useUser } from "../user";

export default () => {
  // view
  const { user } = useUser();

  if (!user.uuid) {
    return (
      <div
        style={{
          display: "flex",
          flex: "1 0 auto",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        <LoginForm />
      </div>
    );
  }
  return (
    <div
      style={{
        display: "flex",
        flex: "1 0 auto",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center"
      }}
    >
      <Typography.Title>Hej {user.name}</Typography.Title>
      <div className="vert-menu">
        <RouterLink route={routes.scribbles}>
          <Typography.Link>
            <ArrowRightOutlined /> Eintagsfliegen ansehen
          </Typography.Link>
        </RouterLink>
        <RouterLink route={routes.scribbleCreate}>
          <Typography.Link>
            <ArrowRightOutlined /> Eintagsfliege erstellen
          </Typography.Link>
        </RouterLink>
        <RouterLink route={routes.documentations}>
          <Typography.Link>
            <ArrowRightOutlined /> Dokumentationen ansehen
          </Typography.Link>
        </RouterLink>
        <RouterLink route={routes.documentationCreate}>
          <Typography.Link>
            <ArrowRightOutlined /> Dokumentation erstellen
          </Typography.Link>
        </RouterLink>
      </div>
    </div>
  );
};
