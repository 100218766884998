import React, { useCallback, useEffect, useRef, useState } from "react";
import { Button, Spin, Typography } from "antd";
import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  FullscreenExitOutlined,
  FullscreenOutlined,
  MehOutlined
} from "@ant-design/icons";
import truncate from "lodash/truncate";

import { completeImageUrl, useStudoApi } from "../connecting/studo";
import ImageListItem from "../components/ImageListItem";
import ClickContainer from "../components/basics/ClickContainer";
import { useUser } from "../user";

const ApiImageListItem = ({ item, isFullscreen }) => {
  const imageUrl = completeImageUrl(item.imageUrl);
  return (
    <ImageListItem
      minHeight={isFullscreen ? "100vh" : "50vh"}
      imageUrl={imageUrl}
      backgroundColor={item.backgroundColor}
      description={
        isFullscreen
          ? item.description
          : truncate(item.description, {
              length: 24,
              separator: " "
            })
      }
      authorName={item.user.name}
    />
  );
};

export default ({ apiPath, listDataKey }) => {
  const [listData, setListData] = useState([]);
  const [studentsData, setStudentsData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [itemsFullscreen, setItemsFullscreen] = useState(false);

  const { user } = useUser();

  const scrollRef = useRef();
  const resetScroll = () => {
    scrollRef.current.scrollLeft = 0;
  };

  const api = useStudoApi({
    onSuccess: (data) => {
      setListData(data[listDataKey]);
      setStudentsData(data.students);
      setFilterData(data.filter);
      setItemsFullscreen(false);
      resetScroll();
    },
    token: user.studoToken
  });

  const refetchForUser = (userUuid) => {
    api.runAction({
      method: "get",
      paramsData: { userUuid },
      path: apiPath
    });
  };

  useEffect(() => {
    api.runAction({ method: "get", path: apiPath });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiPath]);

  const scaleAndScroll = (uuid) => {
    setItemsFullscreen((s) => !s);
    setTimeout(() => {
      document.getElementById(uuid).scrollIntoView({
        behavior: "smooth",
        inline: "start",
        block: "start"
      });
    }, 400);
  };

  return (
    <>
      {studentsData.length > 0 && (
        <div className="user-horz-nav">
          <ClickContainer
            className={`link ${!filterData.userUuid && "link-active"}`}
            onClick={() => refetchForUser(undefined)}
          >
            Alle
          </ClickContainer>
          {studentsData.map((student) => {
            const active = student.uuid === filterData.userUuid;
            return (
              <ClickContainer
                key={student.uuid}
                className={`link ${active && "link-active"}`}
                onClick={() => refetchForUser(student.uuid)}
              >
                {student.name}
              </ClickContainer>
            );
          })}
        </div>
      )}
      <div
        ref={scrollRef}
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "nowrap",
          overflowX: "auto"
        }}
      >
        {api.pending && (
          <div
            style={{
              flex: "0 0 100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              padding: "3rem",
              minHeight: "50vh"
            }}
          >
            <Spin spinning />
          </div>
        )}
        {listData.length === 0 && !api.pending && (
          <div
            style={{
              flex: "0 0 100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              padding: "3rem",
              minHeight: "50vh"
            }}
          >
            <MehOutlined style={{ fontSize: "3rem" }} />
            {!api.error && (
              <Typography.Paragraph>
                Meh, keine Daten gefunden.
              </Typography.Paragraph>
            )}
            {api.error && (
              <>
                <Typography.Paragraph type="danger">
                  Es ist ein Fehler aufgetreten.
                </Typography.Paragraph>
                <Typography.Paragraph>
                  Eventuell bist du nicht mehr angemeldet. Beginne wir von vorn:{" "}
                  <a href="/">Zur Startseite wechseln</a>
                </Typography.Paragraph>
              </>
            )}
          </div>
        )}
        {listData.length > 0 &&
          listData.map((item, index) => (
            <ClickContainer
              id={item.uuid}
              key={item.uuid}
              style={{
                padding: 0,
                position: "relative",
                flex: `1 0 ${itemsFullscreen ? "100%" : "300px"}`,
                transition: "flex 300ms"
              }}
              onClick={() => {
                if (!itemsFullscreen) {
                  scaleAndScroll(item.uuid);
                }
              }}
            >
              {itemsFullscreen && (
                <div style={{ position: "absolute" }}>
                  <Button
                    className="dark-hover"
                    style={{ margin: "1rem", borderColor: "transparent" }}
                    icon={<FullscreenExitOutlined />}
                    ghost
                    shape="circle"
                    onClick={() => {
                      scaleAndScroll(item.uuid);
                    }}
                  />
                </div>
              )}
              <ApiImageListItem
                studoToken={user.studoToken}
                item={item}
                isFullscreen={itemsFullscreen}
              />
              <div
                style={{
                  position: "absolute",
                  bottom: 0,
                  left: 0,
                  right: 0,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center"
                }}
              >
                {!itemsFullscreen && (
                  <Button
                    className="dark-hover"
                    style={{ margin: "1rem", borderColor: "transparent" }}
                    icon={<FullscreenOutlined />}
                    ghost
                    shape="circle"
                  />
                )}
                {index > 0 && itemsFullscreen && (
                  <Button
                    className="dark-hover"
                    style={{ margin: "1rem", borderColor: "transparent" }}
                    icon={<ArrowLeftOutlined />}
                    ghost
                    shape="circle"
                    onClick={() => {
                      document
                        .getElementById(listData[index - 1].uuid)
                        .scrollIntoView({ behavior: "smooth" });
                    }}
                  />
                )}
                {index + 1 < listData.length && itemsFullscreen && (
                  <Button
                    className="dark-hover"
                    style={{ margin: "1rem", borderColor: "transparent" }}
                    icon={<ArrowRightOutlined />}
                    ghost
                    shape="circle"
                    onClick={() => {
                      document
                        .getElementById(listData[index + 1].uuid)
                        .scrollIntoView({ behavior: "smooth" });
                    }}
                  />
                )}
              </div>
            </ClickContainer>
          ))}
      </div>
    </>
  );
};
