import React, { useState, useRef } from "react";
import { Form, Input, Button, Alert, Progress, Typography } from "antd";

import { ArrowRightOutlined, SmileOutlined } from "@ant-design/icons";
import FieldImage from "../components/FormItems/FieldImage";
import { useStudoApi } from "../connecting/studo";
import routes from "../routing/routes";
import { RouterLink } from "../routing";
import FieldColor from "../components/FormItems/FieldColor";
import ImageListItem from "../components/ImageListItem";
import { useUser } from "../user";

export default () => {
  const initialFormValues = {
    image: null,
    description: null,
    backgroundColor: "#292b2f"
  };

  const { user } = useUser();

  const [preview, setPreview] = useState({
    imageUrl: null,
    description: "",
    backgroundColor: "#292b2f"
  });
  const [successFeedback, setSuccessFeedback] = useState({
    show: false,
    uuid: null
  });
  const [uploadFeedback, setUploadFeedback] = useState({
    show: false,
    percent: 0
  });
  const saveOnline = useStudoApi({
    onUploadProgress: (v) =>
      setUploadFeedback({ show: true, percent: parseInt(100 / v, 10) }),
    onEnd: () => setUploadFeedback({ show: false }),
    onSuccess: (data) => {
      setSuccessFeedback({ show: true, uuid: data.scribble.uuid });
    },
    token: user.studoToken
  });

  const [formRef] = Form.useForm();
  const descriptionRef = useRef();

  const handleSubmit = ({ description, backgroundColor, image }) => {
    const data = {
      description,
      backgroundColor
    };
    if (image instanceof File) {
      console.log("image is instance of File");
      data.imageFile = image;
    } else if (image instanceof Blob) {
      console.log("image is instance of Blob");
      data.imageFile = image;
    }

    saveOnline.runAction({
      method: "post",
      path: "/scribbles/create",
      data,
      upload: true
    });
  };

  const saving = saveOnline.pending;

  if (successFeedback.show) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          minHeight: "50vh"
        }}
      >
        <SmileOutlined style={{ fontSize: "3rem" }} />
        <Typography.Paragraph>
          Die Eintagsliege wurde gespeichert.
        </Typography.Paragraph>
        <RouterLink label="Zu den Eintagsfliegen" route={routes.scribbles}>
          <Button type="link" icon={<ArrowRightOutlined />}>
            Zu den Eintagsfliegen
          </Button>
        </RouterLink>
      </div>
    );
  }

  return (
    <>
      <Form
        onFinish={handleSubmit}
        initialValues={initialFormValues}
        layout="vertical"
        form={formRef}
        scrollToFirstError
      >
        <Form.Item
          name="image"
          style={{ width: "100%" }}
          rules={[{ required: true, message: "Eine Grafik/Foto fehlt!" }]}
        >
          <FieldImage
            style={{ display: "flex", justifyContent: "center" }}
            withPreview={false}
            disabled={saving}
            onImageUrl={(imageUrl) => setPreview((s) => ({ ...s, imageUrl }))}
            onFileSelected={() => {
              setTimeout(() => {
                formRef.scrollToField("description", {
                  scrollMode: "always",
                  behavior: "smooth",
                  block: "center"
                });
              }, 100);
              descriptionRef.current.focus({ cursor: "end" });
            }}
          />
        </Form.Item>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <Form.Item
            name="description"
            // noStyle
            rules={[{ required: true, message: "Schreibe etwas!" }]}
            style={{ maxWidth: 320, width: "100%" }}
          >
            <Input.TextArea
              ref={descriptionRef}
              disabled={saving}
              placeholder="Beschreibe die Eintagsfliege kurz!"
              autoSize={{ minRows: 4 }}
              size="large"
              style={{
                backgroundColor: "#292b2f",
                color: "#fff",
                borderColor: "#1890ff",
                maxWidth: 320
              }}
              onChange={({ target: { value: text } }) =>
                setPreview((s) => ({ ...s, description: text }))
              }
            />
          </Form.Item>
        </div>

        <Form.Item name="backgroundColor">
          <FieldColor
            width={336}
            style={{ display: "flex", justifyContent: "center" }}
            onColorSelected={(color) =>
              setPreview((s) => ({ ...s, backgroundColor: color }))
            }
          />
        </Form.Item>

        <ImageListItem
          minHeight={preview.imageUrl ? "100vh" : "10vh"}
          imageUrl={preview.imageUrl}
          backgroundColor={preview.backgroundColor}
          description={preview.description}
          authorName={user.name}
          style={{ marginBottom: "1rem" }}
        />

        {uploadFeedback.show && <Progress percent={uploadFeedback.percent} />}
        {saveOnline.error && (
          <Alert
            style={{ margin: "1rem" }}
            type="error"
            showIcon
            message="Oh nein."
            description={
              saveOnline.error?.response?.data?.error ||
              saveOnline.error.message
            }
          />
        )}

        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            paddingBottom: "1rem"
          }}
        >
          <Button type="primary" htmlType="submit" loading={saving}>
            Speichern
          </Button>
        </div>
      </Form>
    </>
  );
};
