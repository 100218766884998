import React from "react";
import { Typography } from "antd";
import DocumentationForm from "../containers/DocumentationForm";

export default () => (
  <>
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        margin: "2rem"
      }}
    >
      <Typography.Title level={3}>Dokumentation erstellen</Typography.Title>
    </div>
    <DocumentationForm />
  </>
);
