import React, { useCallback, useEffect, useRef, useState } from "react";

export default ({
  imageUrl = null,
  backgroundColor = "#292b2f",
  description = "",
  authorName = "",
  style,
  minHeight = "100vh"
}) => {
  const [lazyImageUrl, setLazyImageUrl] = useState(null);
  const itemRef = useRef(null);
  const itemObserver = useCallback(
    (currentItem) => {
      const intersectionObserver = new IntersectionObserver((item) => {
        if (item[0].isIntersecting) {
          setLazyImageUrl(imageUrl);
          intersectionObserver.unobserve(currentItem);
        }
      });
      intersectionObserver.observe(currentItem);
    },
    [imageUrl]
  );

  useEffect(() => {
    let observer = null;
    if (itemRef.current) {
      observer = itemObserver(itemRef.current);
    }
    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  }, [itemObserver]);

  return (
    <div
      ref={itemRef}
      style={{
        minHeight,
        backgroundImage: lazyImageUrl ? `url(${lazyImageUrl})` : null,
        backgroundSize: "contain",
        backgroundPosition: "25% 50%",
        backgroundRepeat: "no-repeat",
        backgroundColor,
        display: "flex",
        alignContent: "flex-end",
        justifyContent: "flex-end",
        flexDirection: "row",
        border: `2rem solid ${backgroundColor}`,
        borderBottom: `5rem solid ${backgroundColor}`,
        ...style
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "flex-end"
        }}
      >
        <div
          style={{
            textAlign: "right",
            maxWidth: 320,
            width: "100%",
            color: "#fff"
          }}
        >
          {description.split("\n").map((line, lineIndex) => (
            <div key={`${line + lineIndex}--line`}>
              <span style={{ backgroundColor: "#292b2f", color: "#fff" }}>
                {line}
              </span>
            </div>
          ))}

          <i>@{authorName}</i>
        </div>
      </div>
    </div>
  );
};
