import React from "react";
import ReactDOM from "react-dom";
import "antd/dist/antd.css";
import "./index.css";
import smoothscroll from "smoothscroll-polyfill";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./fonts/Cardo-Regular.woff2";
import "./fonts/FuturaPT-Book.woff2";

smoothscroll.polyfill();

ReactDOM.render(<App />, document.getElementById("root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
