import React, { useContext, useEffect, useState } from "react";
import { Spin } from "antd";
import { useStudoApi } from "../connecting/studo";

const UserContext = React.createContext();

const STUDO_TOKEN_LOCALKEY = "studo-token";

export const AppUser = ({ children }) => {
  const [initialLoading, setInitialLoading] = useState(true);
  const initialState = {
    uuid: null,
    name: "gast",
    roleName: null,
    studoToken: localStorage.getItem(STUDO_TOKEN_LOCALKEY)
  };
  const [user, setUser] = useState(initialState);

  const loginApi = useStudoApi({
    onSuccess: (data) => {
      if (data.user) {
        setUser((s) => ({
          ...s,
          uuid: data.user.uuid,
          name: data.user.name,
          roleName: data.user.roleName,
          studoToken: data.token
        }));
      }
    },
    onEnd: () => setInitialLoading(false),
    token: user.studoToken
  });

  useEffect(() => {
    loginApi.runAction({ method: "get", path: "/auth/me" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (user.studoToken === null) {
      localStorage.removeItem(STUDO_TOKEN_LOCALKEY);
    } else if (user.studoToken) {
      localStorage.setItem(STUDO_TOKEN_LOCALKEY, user.studoToken);
    }
  }, [user.studoToken]);

  const resetUser = () => setUser({ ...initialState, studoToken: null });

  if (initialLoading) {
    return (
      <div
        style={{
          display: "flex",
          flex: "1 0 auto",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <Spin spinning tip="Connecting..." />
      </div>
    );
  }

  return (
    <UserContext.Provider value={{ user, setUser, resetUser }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);
