import React, { useEffect, useRef } from "react";

export default ({
  className,
  style,
  children,
  onClick = () => {},
  disabled = false,
  stopPropagation = true,
  id = null
}) => {
  const clickcontainerRef = useRef(null);
  useEffect(() => {
    const clickcontainer = clickcontainerRef.current;
    const preventTouchPropagation = (e) => {
      e.stopPropagation();
    };
    clickcontainer.addEventListener("touchstart", preventTouchPropagation);
    clickcontainer.addEventListener("touchmove", preventTouchPropagation);
    clickcontainer.addEventListener("touchend", preventTouchPropagation);

    return () => {
      clickcontainer.removeEventListener("touchstart", preventTouchPropagation);
      clickcontainer.removeEventListener("touchmove", preventTouchPropagation);
      clickcontainer.removeEventListener("touchend", preventTouchPropagation);
    };
  }, []);

  const handleClick = (e) => {
    console.log("click", id);
    if (disabled) {
      return;
    }
    if (stopPropagation) {
      e.stopPropagation();
    }
    onClick(e);
  };

  const onKeyPress = (e) => {
    const enterOrSpace =
      e.key === "Enter" ||
      e.key === " " ||
      e.key === "Spacebar" ||
      e.which === 13 ||
      e.which === 32;
    if (enterOrSpace) {
      e.preventDefault();
      handleClick(e);
    }
  };

  return (
    <div
      className={`click-container ${className}`}
      ref={clickcontainerRef}
      role="button"
      tabIndex={disabled ? -1 : 0}
      onClick={handleClick}
      onKeyPress={onKeyPress}
      style={style}
      id={id}
    >
      {children}
    </div>
  );
};
