import React, { useState, useEffect, useRef } from "react";
import { Button } from "antd";
import { CameraFilled } from "@ant-design/icons";
import imageCompression from "browser-image-compression";

const useLocalImagePreview = () => {
  const [file, setFile] = useState(null);
  const [imageUrl, setImageUrl] = useState(null);

  const createFromInputFile = (inputFile) => {
    setFile(inputFile);
  };

  useEffect(() => {
    const effectUrl = file ? URL.createObjectURL(file) : null;
    console.log("effecturl is ", effectUrl);
    setImageUrl(effectUrl);
    return () => {
      // on unmount, revoke (neccassary for performance)
      if (effectUrl) {
        URL.revokeObjectURL(effectUrl);
      }
    };
  }, [file]);

  return { imageUrl, createFromInputFile, setImageUrl };
};

export default ({
  value,
  onChange,
  disabled = false,
  withPreview = true,
  style = {},
  onImageUrl = () => {},
  onFileSelected = () => {},
  enableCompression = true
}) => {
  const { imageUrl, createFromInputFile, setImageUrl } = useLocalImagePreview();

  useEffect(() => {
    if (value instanceof File) {
      console.log("value is file");
      createFromInputFile(value);
    } else if (value instanceof Blob) {
      console.log("value is blob");
      createFromInputFile(value);
    } else if (typeof value === "string" && value.indexOf("http") === 0) {
      console.log("value is string with http");
      setImageUrl(value);
    }
  }, [value]);

  useEffect(() => {
    onImageUrl(imageUrl);
  }, [imageUrl]);

  const handleFileChange = async (file) => {
    const options = {
      maxSizeMB: 3,
      maxWidthOrHeight: 1920,
      useWebWorker: true
    };
    if (file) {
      try {
        if (enableCompression) {
          const compressedFile = await imageCompression(file, options);
          onChange(compressedFile);
          onFileSelected(compressedFile);
        } else {
          onChange(file);
          onFileSelected(file);
        }
      } catch (error) {
        console.log("Cant compress file:", error);
        onChange(file);
        onFileSelected(file);
      }
    }
  };

  const inputRef = useRef();

  console.log(
    "image url to render",
    imageUrl ? `url(${imageUrl})` : null,
    !!imageUrl
  );

  return (
    <>
      {withPreview && (
        <div
          style={{
            width: 200,
            height: 200,
            // eslint-disable-next-line no-extra-boolean-cast
            backgroundImage: `url("${imageUrl}")`,
            backgroundSize: "contain",
            backgroundPosition: "center center",
            backgroundRepeat: "no-repeat"
          }}
        >
          <span style={{ color: "#fff" }}>{`url(${imageUrl})`}</span>
        </div>
      )}
      <div
        style={{
          ...style
        }}
      >
        <Button
          size="large"
          icon={<CameraFilled />}
          type="primary"
          htmlType="button"
          onClick={() => inputRef.current.click()}
        >
          Foto
        </Button>
      </div>

      <input
        ref={inputRef}
        type="file"
        accept="image/jpg,image/jpeg,image/png"
        onChange={({ target: { files } }) => handleFileChange(files[0] || null)}
        disabled={disabled}
        style={{ width: 0, height: 0, position: "absolute" }}
      />
    </>
  );
};
