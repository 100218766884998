import React from "react";
import { Button } from "antd";
import { LogoutOutlined } from "@ant-design/icons";
import { useUser } from "../user";
import { useStudoApi } from "../connecting/studo";
import { useRouter } from "../routing";
import routes from "../routing/routes";

export default () => {
  const { user, resetUser } = useUser();
  const { setNextRoute } = useRouter();
  const logoutApi = useStudoApi({
    onSuccess: () => {
      resetUser();
      setNextRoute(routes.root);
    }
  });

  const handleLogout = () => {
    logoutApi.runAction({ method: "post", path: "/auth/signout" });
  };

  if (!user.uuid) {
    return null;
  }

  return (
    <>
      <div style={{ textAlign: "center", margin: "2rem", opacity: 0.3 }}>
        All images &copy; 2021 by named person marked by @<i>name</i>
      </div>
      <div className="nav" style={{ overflowX: "auto" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
          }}
        >
          <div style={{ margin: "1rem" }}>angemeldet als {user.name}</div>
          <div style={{ margin: "1rem" }}>
            <Button
              type="link"
              icon={<LogoutOutlined />}
              loading={logoutApi.pending}
              onClick={handleLogout}
            >
              Logout
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};
