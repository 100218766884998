export default {
  root: {
    path: "/",
    settings: {
      hideUserMainNav: true
    }
  },
  documentations: {
    path: "/documentations",
    accessCheck: (user) => !!user.uuid
  },
  documentationCreate: {
    path: "/documentation/create",
    accessCheck: (user) => !!user.uuid,
    settings: {
      hideUserMainNav: true
    }
  },
  scribbles: {
    path: "/scribbles",
    accessCheck: (user) => !!user.uuid
  },
  scribbleCreate: {
    path: "/scribble/create",
    accessCheck: (user) => !!user.uuid,
    settings: {
      hideUserMainNav: true
    }
  }
};
