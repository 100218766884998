import React from "react";
import { Button } from "antd";
import ApiImageList from "../containers/ApiImageList";
import { RouterLink } from "../routing";
import routes from "../routing/routes";

export default () => (
  <>
    <ApiImageList apiPath="/scribbles" listDataKey="scribbles" />

    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        margin: "3rem 0"
      }}
    >
      <RouterLink label="Scribble erstellen" route={routes.scribbleCreate}>
        <Button type="primary">Neue Eintagsfliege erstellen</Button>
      </RouterLink>
    </div>
  </>
);
